import React from "react";
import Computer from "../../../assets/images/portfolio-computer.webp";
import classes from "./style.module.scss";

const PortoflioBanner = () => (
  <section className={classes.Banner}>
    <div className={classes.Description}>
      <header>
        <h1>Our work is about creating Apps &amp; Websites Systems</h1>
      </header>
      <div className={classes.BasicInformations}>
        <div className={classes.HappyClients}>
          <p>20+</p>
          <p>Team Members</p>
        </div>
        <div className={classes.Awards}>
          <p>30+ </p>
          <p>Satisfied customers</p>
        </div>
        <div className={classes.ProjectsCompleted}>
          <p>40+ </p>
          <p>Projects delivered</p>
        </div>
      </div>
      <div className={classes.Scroll}>
        <span>Scroll down</span>
        <span />
      </div>
    </div>
    <div className={classes.ComputerBg}>
      <img src={Computer} alt="Computer" />
    </div>
  </section>
);

export default PortoflioBanner;
