import React from 'react';
import { A, usePath, navigate } from 'hookrouter';
import Button from '../../buttons';
import classes from './style.module.scss';
import { ReactComponent as Logo } from '../../../assets/svg/logo-white-svg.svg';
import LogoBlue from '../../../assets/images/logo-blue.jpg';
import { ReactComponent as Facebook } from '../../../assets/svg/facebook-logo.svg';
import { ReactComponent as Linkedin } from '../../../assets/svg/linked-in-logo.svg';
import { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import HamburgerMenu from 'react-hamburger-menu';

const artcileRoute = '/blog/article';
const jobsRoute = '/careers/*';

smoothscroll.polyfill();

const Header = () => {
  const currentPath = usePath();
  const isRoute =
    currentPath.includes(jobsRoute) || currentPath.includes(artcileRoute);
  const headerStyle = isRoute
    ? [classes.Header, classes.White].join(' ')
    : classes.Header;
  const linksStyle = isRoute ? classes.ColorLinks : '';
  const whiteHamburger = isRoute ? classes.WhiteMenuButton : '';
  const whiteSocialStyle = isRoute ? classes.whiteSocialIconsStyle : '';
  const letsTalkStyle = currentPath.includes(artcileRoute)
    ? [classes.Contact, classes.ButtonBorder].join(' ')
    : classes.Contact;

  const moveToContactForm = () => {
    if (currentPath !== '/') navigate('/');
    scrollToContactForm();
  };

  const scrollToContactForm = () => {
    setTimeout(() => {
      const contactForm = document.getElementById('contact-form');
      contactForm && contactForm.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };
  class Hamburger extends Component {
    container = React.createRef();
    state = {
      open: false,
    };
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = event => {
      if (
        this.container.current &&
        !this.container.current.contains(event.target)
      ) {
        this.setState({
          open: false,
        });
      }
    };
    handleButtonClick = () => {
      this.setState(state => {
        return {
          open: !state.open,
        };
      });
    };
    render() {
      return (
        <div className={classes.Hamburger}>
          <div className={classes.Container} ref={this.container}>
            <div className={[classes.MenuButton, whiteHamburger].join(' ')}>
              <HamburgerMenu
                isOpen={this.state.open}
                menuClicked={this.handleButtonClick.bind(this)}
                width={30}
                height={30}
                strokeWidth={3}
                rotate={1}
                color='white'
                borderRadius={0}
                animationDuration={0.5}
                className={this.state.open ? classes.Active : ''}
              />
            </div>

            {this.state.open && (
              <nav
                itemScope
                itemType='http://www.schema.org/SiteNavigationElement'
              >
                <ul
                  className={
                    this.state.open ? classes.MenuList : classes.Active
                  }
                >
                  <li className={classes.MenuCloseItem}></li>
                  <li className={classes.MenuItem}>
                    <A itemProp='url' href='/' className={linksStyle}>
                      Home
                    </A>
                  </li>
                  <li itemProp='name' className={classes.MenuItem}>
                    <A itemprop='url' href='/about/' className={linksStyle}>
                      About
                    </A>
                  </li>
                  <li itemProp='name' className={classes.MenuItem}>
                    <A itemprop='url' href='/portfolio/' className={linksStyle}>
                      Our Work
                    </A>
                  </li>
                  <li itemProp='name' className={classes.MenuItem}>
                    <A itemProp='url' href='/careers/' className={linksStyle}>
                      Careers
                    </A>
                  </li>
                  {/*  <li className={classes.MenuItem}>
                    <A
                      itemProp='url'
                      href='/oneweekapp/'
                      className={linksStyle}
                    >
                      App in one Week
                    </A>
                  </li> */}
                  <li className={classes.HideContact}>
                    <Button
                      className={classes.MenuItem}
                      text='Contact'
                      onClick={moveToContactForm}
                    />
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      );
    }
  }

  return (
    <header className={headerStyle}>
      <div className={'MainWrapper ' + classes.MenuWrapper}>
        <A href='/' className={classes.Logo}>
          {isRoute ? (
            <img src={LogoBlue} alt='Logo' className={classes.LogoBlue} />
          ) : (
            <Logo />
          )}
        </A>
        <Hamburger />
        <nav
          itemScope='itemScope'
          itemType='http://www.schema.org/SiteNavigationElement'
        >
          <ul className={classes.MenuList}>
            <li className={classes.MenuItem}>
              <A itemProp='url' href='/' className={linksStyle}>
                Home
              </A>
            </li>
            <li className={classes.MenuItem}>
              <A itemProp='url' href='/about/' className={linksStyle}>
                About
              </A>
            </li>
            <li className={classes.MenuItem}>
              <A itemProp='url' href='/portfolio/' className={linksStyle}>
                Our Work
              </A>
            </li>
            <li className={classes.MenuItem}>
              <A itemProp='url' href='/careers/' className={linksStyle}>
                Careers
              </A>
            </li>
            {/*<li className={classes.MenuItem}>
              <A itemProp='url' href='/oneweekapp/' className={linksStyle}>
                App in one Week
              </A>
            </li>
            */}
          </ul>
        </nav>
        <div className={classes.SocialMedia}>
          {!currentPath.includes(jobsRoute) && (
            <Button
              className={letsTalkStyle}
              text="Let's talk!"
              onClick={moveToContactForm}
            />
          )}
          <a
            href='https://pl.linkedin.com/company/react-poland'
            target='_blank'
            rel='noopener noreferrer'
            className={[
              classes.MediaLink,
              classes.MenuTopIn,
              whiteSocialStyle,
            ].join(' ')}
          >
            <Linkedin />
          </a>
          <a
            href='https://www.facebook.com/reactpolandsoftware/'
            target='_blank'
            rel='noopener noreferrer'
            className={[
              classes.MediaLink,
              classes.MenuTopFb,
              whiteSocialStyle,
            ].join(' ')}
          >
            <Facebook />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
