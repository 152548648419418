import React from 'react';
import { ReactComponent as Location } from '../../../../assets/svg/location.svg';
import { ReactComponent as WiFi } from '../../../../assets/svg/wifi-connection.svg';
import { ReactComponent as House } from '../../../../assets/svg/home-run.svg';
import classes from './style.module.scss';

const benefits = [
  `Impact on our projects`,
  `Flexible working hours`,
  //`Equipment of your choice (MacBook Pro or Dell)`,
  `Workplace in one of our offices in the city centre`,
  `Multisport card`,
  `Team trips and events`,
  `Free drinks`,
  `Subsidized courses`,
  `Integration events`,
];

const JobDescription = ({ data = {} }) => {
  const getLevelsLang = item => {
    const levelsLang = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'Native'];
    const { lang, level } = item;

    return (
      <tr key={item.lang}>
        <td>{lang}</td>
        {levelsLang.map(lev => (
          <td key={lev} style={level === lev ? { fontWeight: 'bold' } : {}}>
            {lev}
          </td>
        ))}
      </tr>
    );
  };

  const getJobOffer = () => {
    const {
      title,
      location,
      option,
      responsibilitiesDesc,
      responsibilities,
      mustHave,
      additionalSkills,
      languages,
      conditions,
    } = data;

    return (
      <>
        <div className={classes.Title}>
          <h1>{title}</h1>
          {/*{setDatePeriod(date)}*/}
        </div>
        <div className={classes.Info}>
          <p>
            {<Location />} &nbsp;ReactPoland, {location}
          </p>
          <p>
            {option === 'Inhouse' ? <House /> : <WiFi />} &nbsp; {option}
          </p>
        </div>
        <div className={classes.DetailsSection}>
          <h2>Job description</h2>
          <p className={classes.Company}>
            React Poland is a Polish software house from Silesia region. Our
            dynamic growth leads us to the point in which we need you on board.
            Become a part of our international team as a {title}.
          </p>
          <p className={classes.responsibilitiesDesc}>{responsibilitiesDesc}</p>
          {responsibilities.map((point, index) => (
            <p key={index}>{point}</p>
          ))}
        </div>
        <div className={classes.DetailsSection}>
          <h2>Requirements &amp; Skill</h2>
          <div className={classes.Skills}>
            <div className={classes.Subsections}>
              <p>Must have</p>
              {mustHave.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className={classes.Subsections}>
              <p>Additional Assets</p>
              {additionalSkills.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.DetailsSection}>
          <h2>Languages</h2>
          <div className={classes.LanguageTable}>
            <table>
              <tbody>{languages.map(item => getLevelsLang(item))}</tbody>
            </table>
          </div>
        </div>
        <div className={classes.DetailsSection}>
          <h2>Benefits &amp; Extras</h2>
          <div className={classes.Benefits}>
            <div className={classes.Subsections}>
              <p />
              {benefits.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.DetailsSection}>
          <h2>Work Conditions</h2>
          <div className={classes.WorkConditions}>
            <table>
              <tbody>
                {conditions.map(item => (
                  <tr key={item.label}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const notFoundOffer = () => (
    <div className={classes.Title}>
      <h1>Offer not found</h1>
    </div>
  );

  return (
    <article className={classes.JobWrapper}>
      {Object.keys(data).length ? getJobOffer() : notFoundOffer()}
    </article>
  );
};

export default JobDescription;
