import React from 'react';
import { useState } from 'react';
import Button from '../../../buttons';
import { ReactComponent as IconIn } from '../../../../assets/svg/linked-in-logo.svg';
import classes from './style.module.scss';

const Apply = ({ data }) => {
  const getIconIn = () => (
    <span className={classes.IconIn}>
      <IconIn />
    </span>
  );

  const [apply, setApply] = useState(false);
  const [hideButton, setHideButton] = useState(true);

  const toggleApply = () => {
    setApply(!apply);
    setHideButton(!hideButton);
    setTimeout(() => {
      const jobForm = document.getElementById('form-wrapper');
      jobForm && jobForm.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  if (!data) return null;

  return (
    <aside className={classes.ApplyWrapper}>
      {/*
      <p>
        <img src={IconShare} alt='Share' /> Share this job opening
      </p>
      */}
      <Button
        className={[classes.ButtonApply, classes.ButtonNow].join(' ')}
        text='APPLY NOW'
        onClick={toggleApply}
      />

      <Button
        className={classes.ButtonApply}
        icon={getIconIn()}
        text={`Apply with`}
        onClick={() => console.log('Apply with')}
      />
    </aside>
  );
};

export default Apply;
