import React from 'react';
import Woman from '../../../assets/images/Girl.png';
import { A } from 'hookrouter';
import classes from './style.module.scss';

const Career = () => {
  const toTheTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };
  return (
    <section className={classes.Career}>
      <div className={classes.CareerWave}>
        <svg
          viewBox='0 0 500 150'
          preserveAspectRatio='none'
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <path
            d='M0.00,49.98 C149.99,150.00 365.40,150.48 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'
            style={{
              stroke: 'none',
              fill: '#f8f8f8',
            }}
          />
        </svg>
      </div>
      <div className='MainWrapper'>
        <div className={classes.Card}>
          <div className={classes.Image}>
            <img src={Woman} alt='Woman of success' />
          </div>
          <div className={`${classes.Description} TextStyles`}>
            <h3>
              Do you want to grow your
              <br />
              career with React Poland?
            </h3>
            <p>
              As a fast growing organization, we need new passionate geeks on
              board.
              <br />
              Find the vacancy for you and become a part of our great team.
            </p>
            <A
              href='/careers/'
              className={classes.Button}
              onClick={() => toTheTop()}
            >
              Go to Careers
            </A>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Career;
