import React from 'react';
import { useRoutes } from 'hookrouter';
import { Home } from './pages/home';
import { Careers } from './pages/careers';
import { About } from './pages/about';
import { Blog } from './pages/blog';
import { Portfolio } from './pages/portfolio/';
import { OurWork } from './pages/ourWork';
import { ErrorPage } from './pages/error';
import AppInOneWeek from './pages/appInOneWeek/appInOneWeek';
import Header from './components/layout/header';
import Footer from './components/layout/footer';
// eslint-disable-next-line no-lone-blocks
{
  /*
const Home = lazy(() => import('./pages/home'));
const Careers = lazy(() => import('./pages/careers'));
const About = lazy(() => import('./pages/about'));
const Portfolio = lazy(() => import('./pages/portfolio/'));
const ErrorPage = lazy(() => import('./pages/error'));
const Blog = lazy(() => import('./pages/blog'));
*/
}
const routes = {
  '/': () => <Home />,
  '/about/': () => <About />,
  '/about': () => <About />,

  //'/oneweekapp': () => <AppInOneWeek />,
  //'/oneweekapp/': () => <AppInOneWeek />,

  '/careers': () => <Careers />,
  '/careers/': () => <Careers />,

  '/careers/:id/:position': ({ id, position }) => (
    <Careers id={id} position={position} />
  ),

  '/careers/:id/:position/': ({ id, position }) => (
    <Careers id={id} position={position} />
  ),

  '/portfolio': () => <Portfolio />,
  '/portfolio/': () => <Portfolio />,

  '/our-work': () => <OurWork />,
  '/blog': () => <Blog />,
  '/blog/': () => <Blog />,

  '/blog/article/:id': ({ id }) => <Blog id={id} />,
  '/blog/article/:id/': ({ id }) => <Blog id={id} />,
};

function App() {
  const routeResult = useRoutes(routes);
  return (
    <div className='App'>
      <Header />
      {routeResult || <ErrorPage />}
      <Footer />
    </div>
  );
}

export default App;
