import Experty from "../assets/images/testimonials/Experty2.webp";
import fas from "../assets/images/testimonials/2fas.webp";
import Misebox from "../assets/images/testimonials/misebox.webp";
import Smallmu from "../assets/images/testimonials/smallmu2.webp";
import Stickbullit from "../assets/images/testimonials/stickbullit.webp";

import img1 from "../assets/images/team/1.jpg";
import img2 from "../assets/images/team/2.jpg";
import img3 from "../assets/images/team/3.jpeg";
import img4 from "../assets/images/team/4.jpeg";
import img5 from "../assets/images/team/5.jpg";
import img6 from "../assets/images/team/6.jpg";
import img7 from "../assets/images/team/7.jpg";

export const testimonials = [
  {
    id: 1,
    src: Experty,
    alt: "Experty",
    opinion: `Building a reliable and scalable product on the global market requires specialists in every inch. Undoubtedly, React Poland is a technology partner that meets these requirements.`,
    author: "Kamil Przeorski",
    occupation: "CEO at Experty",
  },
  {
    id: 2,
    src: fas,
    alt: "2fas",
    opinion: `I would like to strongly recommend cooperation with React Poland in the area of building mobile applications. They helped us build the 2FAS application using React-Native from scratch.`,
    author: "Marek Bardziński",
    occupation: "Owner of 2FAS",
  },
  {
    id: 3,
    src: Misebox,
    alt: "MISEbox",
    opinion: `Both the cooperation process itself and the quality of the provided code allowed us to implement our idea relatively quickly. At the same time, efficient communication with programmers and project management has streamlined the entire process.`,
    author: "Mr. Dana A. Koteen",
    occupation: "Managing Partner",
  },
  {
    id: 4,
    src: Smallmu,
    alt: "Small Multiples",
    opinion: `Their experience in ReactJS framework was the reason we chose to work with them, and the demonstrated their capabilities very well during the project.
    `,
    author: "Andrea Lau",
    occupation: "Director and Data Visualisation Specialist",
  },
  {
    id: 5,
    src: Stickbullit,
    alt: "Stickbuilt",
    opinion: `We have worked with React Poland both in the capacity of subcontracting out web portions of larger contracts, and in building web portions of our own internal products. In both cases the principals of the company as well as the engineers have been a pleasure to work with, and provide solid value.`,
    author: "Ian Peters-Campbell",
    occupation: "CEO at Stickbulit Inc",
  },
];

export const offers = [
  {
    id: 1,
    title: "Junior Software QA Engineer",
    adsStart: "Added 17 days ago",
    description:
      "We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native.",
    responsibilitiesDesc: `We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native. Primary responsibilities for the Junior QA are listed below:`,
    responsibilities: [
      `- Verification, testing and troubleshooting on mobile and web platforms`,
      `- Close collaboration with the other team members`,
      `- Execution of Functional and Regression testing`,
      `- Detection, reporting and tracking software defects and inconsistencies`,
    ],
    mustHave: [
      `Knowledge based on ISTQB in practice is a crucial skill`,
      `Mindset capable to work on a few projects at the same time`,
      `Great interpersonal and communication skills`,
      `Critical thinking and problem-solving skills with attention to details`,
      `Mindset capable to work on a few projects at the same time`,
      `Willingness to learn and to develop skills`,
      `Proactive attitude and good with time management`,
      `Strong sense of ownership in testing - not just of the specification but
             with a real clients and use cases`,
    ],
    additionalSkills: ["Java Basics"],
    languages: [
      { lang: "Polish", level: "C2" },
      { lang: "English", level: "B2" },
    ],
    location: "Gliwice, Poland",
    option: "Remote friendly",
    conditions: [
      { label: "Job type", value: "Full-time" },
      {
        label: "Contract type",
        value: "Employment / B2B / Contract Agreement / Contract",
      },
      {
        label: "Contract duration",
        value: "1 year with possibility of extension",
      },
      { label: "Salary range", value: "2600 - 4500 PLN" },
    ],
  },
  {
    id: 2,
    title: "Front End Developer",
    adsStart: "Added 18 days ago",
    description:
      "We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native.",
    responsibilitiesDesc: `We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native. Primary responsibilities for the Junior QA are listed below:`,
    responsibilities: [
      `- Verification, testing and troubleshooting on mobile and web platforms`,
      `- Close collaboration with the other team members`,
      `- Execution of Functional and Regression testing`,
      `- Detection, reporting and tracking software defects and inconsistencies`,
    ],
    mustHave: [
      `Knowledge based on ISTQB in practice is a crucial skill`,
      `Mindset capable to work on a few projects at the same time`,
      `Great interpersonal and communication skills`,
      `Critical thinking and problem-solving skills with attention to details`,
      `Mindset capable to work on a few projects at the same time`,
      `Willingness to learn and to develop skills`,
      `Proactive attitude and good with time management`,
      `Strong sense of ownership in testing - not just of the specification but
             with a real clients and use cases`,
    ],
    additionalSkills: ["Java Basics"],
    languages: [
      { lang: "Polish", level: "B2" },
      { lang: "English", level: "C1" },
    ],
    location: "Gliwice, Poland",
    option: "Inhouse",
    conditions: [
      { label: "Job type", value: "Full-time" },
      {
        label: "Contract type",
        value: "Employment / B2B / Contract Agreement / Contract",
      },
      {
        label: "Contract duration",
        value: "1 year with possibility of extension",
      },
      { label: "Salary range", value: "4000 - 6000 PLN" },
    ],
  },
  {
    id: 3,
    title: "React Developer",
    adsStart: "Added 23 days ago",
    description:
      "We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native.",
    responsibilitiesDesc: `We are working on various different projects which involve 15 people on board. Our main focus are  web and mobile applications created in React and React Native. Primary responsibilities for the Junior QA are listed below:`,
    responsibilities: [
      `- Verification, testing and troubleshooting on mobile and web platforms`,
      `- Close collaboration with the other team members`,
      `- Execution of Functional and Regression testing`,
      `- Detection, reporting and tracking software defects and inconsistencies`,
    ],
    mustHave: [
      `Knowledge based on ISTQB in practice is a crucial skill`,
      `Mindset capable to work on a few projects at the same time`,
      `Great interpersonal and communication skills`,
      `Critical thinking and problem-solving skills with attention to details`,
      `Mindset capable to work on a few projects at the same time`,
      `Willingness to learn and to develop skills`,
      `Proactive attitude and good with time management`,
      `Strong sense of ownership in testing - not just of the specification but
             with a real clients and use cases`,
    ],
    additionalSkills: ["Java Basics"],
    languages: [
      { lang: "Polish", level: "C2" },
      { lang: "English", level: "B2" },
    ],
    location: "Gliwice, Poland",
    option: "Remote friendly",
    conditions: [
      { label: "Job type", value: "Full-time" },
      {
        label: "Contract type",
        value: "Employment / B2B / Contract Agreement / Contract",
      },
      {
        label: "Contract duration",
        value: "1 year with possibility of extension",
      },
      { label: "Salary range", value: "5000 - 8000 PLN" },
    ],
  },
  {
    id: 4,
    title: "Office Manager",
    adsStart: "Added 2 days ago",
    description:
      "We are working on various different projects which involve + 20 people on board. Our main focus are  web and mobile applications created in React and React Native.",
    responsibilitiesDesc: `React Poland is a Polish software house from the Silesia region. Our dynamic growth leads us to the point in which we need you on board. Become a part of our team as a Junior Office Manager. We are working on various different projects which involve + 20 people on board. Our main focus are web and mobile applications created in React and React Native. We need a full of positive energy person who will run the office and support basic administration.`,
    responsibilities: [
      `- Organising company events or conferences`,
      `- Ordering supplies and beverages`,
      `- Dealing with correspondence`,
      `- Supervising and monitoring the work of administration office`,
      `- Implementing and maintaining procedures`,
      `- Keeping personnel records up to date`,
    ],
    mustHave: [
      `Organisational skills`,
      `Experience in an administrative role (nice to have)`,
      `Knowledge of GSuite package`,
      `Good interpersonal and time management skills.`,
      `Adaptability`,
      `Communication, negotiation and relationship-building skills`,
      `Problem solving skills`,
      `Initiative and proactivity`,
      `Leadership and the ability to "make things happen"`,
      `Attention to details`,
      `Experience in an administrative role (nice to have)`,
    ],
    additionalSkills: [],
    languages: [
      { lang: "Polish", level: "C2" },
      { lang: "English", level: "B2" },
    ],
    location: "Gliwice, Poland",
    option: "Inhouse",
    conditions: [
      { label: "Job type", value: "Full-time" },
      {
        label: "Contract type",
        value: "Employment / B2B / Contract Agreement / Contract",
      },
      {
        label: "Contract duration",
        value: "1 year with possibility of extension",
      },
      // { label: "Salary range", value: "5000 - 8000 PLN" },
    ],
  },
];

export const articles = [
  {
    id: 1,
    title: "Title 1. Title of your article should be placed here",
    date: "January 5, 2020",
    tags: ["workspace", "teamwork", "reactlanguage", "homeoffice"],
    image:
      "https://pbs.twimg.com/profile_images/814599911060766724/0ainlmNK_400x400.jpg",
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiammollis nisi ante, eget pretium purus sagittis quis. 	Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut 			pellentesque purus velit, quis maximus sapien malesuada eu. Cras magna erat, maximus at accumsan at, semper nec mi. 
		
			Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. 
			
			Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis,
			dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur
			consectetur. Vestibulum et feugiat lorem, et semper felis. Vivamus pretium purus nec justo ornare, et porta metus ultrices. Mauris a turpis sem. Sed aliquam id arcu quis lobortis. Etiam vehicula, enim auctor rutrum varius, turpis sapien commodo lacus, eu elementum magna nisi at sapien. Nulla rutrum felis ut volutpat semper. Etiam quis aliquam dui. 
			
			Cras magna justo, tristique a augue eget, egestas venenatis sem. Vivamus ac interdum nisl, ac dignissim tellus. Ut commodo vehicula mauris id volutpat. Nam laoreet mollis diam a commodo. Sed egestas neque a suscipit venenatis. Duis mollis lacinia odio. Aenean euismod faucibus purus, at semper ex gravida ut. Aliquam in ex volutpat, lacinia est ac, interdum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis nisi ante, eget pretium purus sagittis quis. Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut pellentesque purus velit, quis maximus sapien malesuada eu.
			
			Cras magna erat, maximus at accumsan at, semper nec mi. Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec
			suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis, dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur consectetur. Vestibulum et feugiat lorem, et semper felis.`,
    category: "office_life",
  },
  {
    id: 2,
    title: "Title 2",
    date: "May 7, 2020",
    tags: [
      "workspace",
      "teamwork",
      "reactlanguage",
      "homeoffice",
      "remotework",
    ],
    image:
      "https://i.chzbgr.com/full/320517/hF2F61F54/stock-photos-of-man-dubbed-harold",
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiammollis nisi ante, eget pretium purus sagittis quis. 	Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut 			pellentesque purus velit, quis maximus sapien malesuada eu. Cras magna erat, maximus at accumsan at, semper nec mi. 
		
			Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. 
			
			Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis,
			dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur
			consectetur. Vestibulum et feugiat lorem, et semper felis. Vivamus pretium purus nec justo ornare, et porta metus ultrices. Mauris a turpis sem. Sed aliquam id arcu quis lobortis. Etiam vehicula, enim auctor rutrum varius, turpis sapien commodo lacus, eu elementum magna nisi at sapien. Nulla rutrum felis ut volutpat semper. Etiam quis aliquam dui. 
			
			Cras magna justo, tristique a augue eget, egestas venenatis sem. Vivamus ac interdum nisl, ac dignissim tellus. Ut commodo vehicula mauris id volutpat. Nam laoreet mollis diam a commodo. Sed egestas neque a suscipit venenatis. Duis mollis lacinia odio. Aenean euismod faucibus purus, at semper ex gravida ut. Aliquam in ex volutpat, lacinia est ac, interdum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis nisi ante, eget pretium purus sagittis quis. Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut pellentesque purus velit, quis maximus sapien malesuada eu.
			
			Cras magna erat, maximus at accumsan at, semper nec mi. Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec
			suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis, dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur consectetur. Vestibulum et feugiat lorem, et semper felis.`,
    category: "ux_tips",
  },
  {
    id: 3,
    title: "Title 3",
    date: "May 10, 2020",
    tags: ["workspace", "teamwork", "reactlanguage", "homeoffice"],
    image:
      "https://pbs.twimg.com/profile_images/814599911060766724/0ainlmNK_400x400.jpg",
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiammollis nisi ante, eget pretium purus sagittis quis. 	Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut 			pellentesque purus velit, quis maximus sapien malesuada eu. Cras magna erat, maximus at accumsan at, semper nec mi. 
		
			Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. 
			
			Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis,
			dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur
			consectetur. Vestibulum et feugiat lorem, et semper felis. Vivamus pretium purus nec justo ornare, et porta metus ultrices. Mauris a turpis sem. Sed aliquam id arcu quis lobortis. Etiam vehicula, enim auctor rutrum varius, turpis sapien commodo lacus, eu elementum magna nisi at sapien. Nulla rutrum felis ut volutpat semper. Etiam quis aliquam dui. 
			
			Cras magna justo, tristique a augue eget, egestas venenatis sem. Vivamus ac interdum nisl, ac dignissim tellus. Ut commodo vehicula mauris id volutpat. Nam laoreet mollis diam a commodo. Sed egestas neque a suscipit venenatis. Duis mollis lacinia odio. Aenean euismod faucibus purus, at semper ex gravida ut. Aliquam in ex volutpat, lacinia est ac, interdum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis nisi ante, eget pretium purus sagittis quis. Curabitur odio sem, maximus eget semper ac, tristique vitae ipsum. Curabitur dignissim mi ut placerat rutrum. Ut pellentesque purus velit, quis maximus sapien malesuada eu.
			
			Cras magna erat, maximus at accumsan at, semper nec mi. Nulla lacinia justo nunc, id cursus nulla finibus sit amet. Donec
			suscipit volutpat dictum. Etiam consectetur lectus quis lobortis venenatis. Nunc eu ligula hendrerit, eleifend quam finibus, fringilla enim. Nunc facilisis facilisis mi, id euismod turpis semper at. In ante orci, tempus vel diam vel, scelerisque sodales justo. Vestibulum at placerat purus. Aliquam lacinia a leo non cursus. Vestibulum pretium congue nisl ut luctus. Aenean vel tempor sapien. In massa turpis, dignissim in urna sit amet, aliquam egestas ipsum. Sed ac dignissim leo. Vivamus laoreet nulla ut arcu consectetur consectetur. Vestibulum et feugiat lorem, et semper felis.`,
    category: "react_JS",
  },
];

export const team = [
  {
    id: 1,
    src: img1,
    width: 4,
    height: 4,
  },
  {
    id: 2,
    src: img6,
    width: 5,
    height: 4,
  },
  {
    id: 3,
    src: img3,
    width: 5,
    height: 4,
  },
  {
    id: 4,
    src: img4,
    width: 4,
    height: 3,
  },
  {
    id: 5,
    src: img5,
    width: 5,
    height: 3,
  },
  {
    id: 6,
    src: img2,
    width: 4,
    height: 3,
  },
  {
    id: 7,
    src: img7,
    width: 4,
    height: 3,
  },
];

export const appInOneWeekData = [
  {
    id: 1,
    title: "Test 1",
    description: "Lorem",
    image:
      "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?cs=srgb&dl=pexels-harrison-candlin-2441454.jpg&fm=jpg",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 2,
    title: "Night",
    description: "City",
    image:
      "https://images.pexels.com/photos/3125171/pexels-photo-3125171.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 3,
    title: "Test 1",
    description: "Lorem",
    image:
      "https://images.pexels.com/photos/3690005/pexels-photo-3690005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 4,
    title: "Test 4",
    description: "Lorem",
    image:
      "https://images.pexels.com/photos/3690005/pexels-photo-3690005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 5,
    title: "Night",
    description: "City",
    image:
      "https://images.pexels.com/photos/3125171/pexels-photo-3125171.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 6,
    title: "Night",
    description: "City",
    image:
      "https://images.pexels.com/photos/3125171/pexels-photo-3125171.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 7,
    title: "Test 4",
    description: "Lorem",
    image:
      "https://images.pexels.com/photos/3690005/pexels-photo-3690005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 8,
    title: "Night",
    description: "City",
    image:
      "https://images.pexels.com/photos/3125171/pexels-photo-3125171.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
  {
    id: 9,
    title: "Night",
    description: "City",
    image:
      "https://images.pexels.com/photos/3125171/pexels-photo-3125171.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
    linkApple: null,
    linkAndroid: null,
    blogLink: "/blog/",
  },
];

export const exampleCode = `componentDidMount() {
    const { isDisplayed } = this.props;

    isDisplayed ? getNewPosition() : getOldPosition();
};

const formatNumberType = (type, value, precision = 2) => {
    if (value) {
      if (type === 'percent') {
        let roundValue = value.toFixed(precision);
  
        if (parseFloat(0) === parseFloat(roundValue)) roundValue = 0;
  
        return roundValue+%;
      } else {
        return value.toFixed(precision);
      }
    };
};

`;
