import React from 'react';
import classes from './style.module.scss';

const Banner = React.memo(({ id }) => {
  const blogBannerStyle = id
    ? classes.BannerArticleWrapper
    : classes.BannerWrapper;

  return (
    <div className={blogBannerStyle}>
      {!id && (
        <>
          <div className={classes.HeaderText}>
            <header>
              <h1>Blog &amp; Articles</h1>
            </header>
            <p>We want to share with you our knowlage and thoughts.</p>
          </div>
          <div className={classes.Scroll}>Scroll down</div>
        </>
      )}
    </div>
  );
});

export default Banner;
