import React from 'react';
import { Helmet } from 'react-helmet';
import OneWeekAppBanner from '../../components/banners/oneWeekApp';
import CaseStudy from '../../components/content/caseStudy';

function AppInOneWeek() {
  return (
    <div>
      <Helmet>
        <meta
          property='og:title'
          content={`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}`}
        />
        <meta charSet='utf-8' />
        <title>
          {`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}`}
        </title>

        <meta
          name='description'
          content={`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}: The React Poland is a focused web and mobile applications development company located in Poland. The company was founded in 2014, and all our past and current projects use ReactJS or React-Native. No compromises here, React is our main focus.`}
        />
        <meta
          property='og:description'
          content='The React Poland is a focused web and mobile applications development company located in Poland. The company was founded in 2014, and all our past and current projects use ReactJS or React-Native. No compromises here, React is our main focus.'
        />
        <meta
          property='og:image'
          content='https://uploads-ssl.webflow.com/5cc2e264e18ab81050275c14/5d0365ce5349348eba96023c_image.png'
        />
        <meta property='og:url' content='https://www.reactpoland.com/' />
      </Helmet>
      <OneWeekAppBanner />
      <CaseStudy />
    </div>
  );
}

export default AppInOneWeek;
