import React from 'react';
import Banner from '../../components/banners/blog';
import Article from '../../components/content/article';
import Articles from '../../components/content/articles';
import { Helmet } from 'react-helmet';

export const Blog = ({ id }) => (
  <div className='MainContent'>
    <Helmet>
      <meta charSet='utf-8' />
      <title>Blog | React Poland</title>
      <meta name='robots' content='noindex nofollow' />
      <meta property='og:title' content='Blog | React Poland' />
      <meta
        property='og:description'
        content='Blog & Article | We want to share with you our knowlage and thoughts.'
      />
      <meta
        property='og:image'
        content='https://uploads-ssl.webflow.com/5cc2e264e18ab81050275c14/5d0365ce5349348eba96023c_image.png'
      />
      <meta property='og:url' content='www.reactpoland.com/blog/' />
    </Helmet>
    <Banner id={id} />
    {id ? <Article id={id} /> : <Articles />}
  </div>
);

export default Blog;
