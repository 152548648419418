import React, { useState } from 'react';
import Redirect from './jobDetalis/Redirect';
import JobDescription from './jobDetalis/JobDescription';
import Apply from './jobDetalis/Apply';
import OpenRoles from '../openRoles';
import Button from '../../buttons';
import ImageOffice from '../../../assets/images/office-jobs.png';
import classes from './style.module.scss';
import JobForm from '../jobs/jobForm/';

const JobsDetails = ({ data, id, position }) => {
  const [showOffers, setShowOffers] = useState(false);

  const getAllOffers = value => data.filter(({ id }) => id !== value);
  const getCurrentOffer = () =>
    data.find(
      offer => offer.id === Number(id) && offer.title === decodeURI(position)
    );

  const toggleShowOffers = e => {
    const offersPosition =
      e.target.parentElement.previousElementSibling.clientHeight;
    const position = showOffers ? 0 : offersPosition;

    !showOffers && window.scrollTo({ top: position, behavior: 'smooth' });
    setShowOffers(!showOffers);
  };

  return (
    <>
      <section className={classes.JobDetails}>
        <Redirect />
        <JobDescription id={id} data={getCurrentOffer()} />
        <Apply data={getCurrentOffer()} />
      </section>
      {showOffers && (
        <OpenRoles
          data={getAllOffers(id)}
          hideText={classes.HideOpenRolesText}
        />
      )}
      <section className={classes.ActionButtons}>
        <JobForm />
        <Button
          text={`${showOffers ? 'HIDE' : 'SHOW'} ALL OFFERS`}
          className={classes.ActionButton}
          onClick={toggleShowOffers}
        />
      </section>

      <img src={ImageOffice} alt='Office' className={classes.OfficeImage} />
    </>
  );
};

export default JobsDetails;
