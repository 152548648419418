import React from 'react';

const Button = ({ className, disabled, hide, icon, onClick, text, type }) => {
  if (hide) return null;
  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {text} {icon && icon}
    </button>
  );
};
export default Button;
