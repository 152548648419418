import React from 'react';
import { ReactComponent as IconReact } from '../../assets/svg/technologies-icons/ico_react.svg';
import { ReactComponent as IconRedux } from '../../assets/svg/technologies-icons/ico_redux.svg';
import { ReactComponent as IconNode } from '../../assets/svg/technologies-icons/ico_node.svg';
import { ReactComponent as IconPostgres } from '../../assets/svg/technologies-icons/ico_postgresql.svg';
import { ReactComponent as IconWebpack } from '../../assets/svg/technologies-icons/ico_webpack.svg';
import { ReactComponent as IconExpress } from '../../assets/svg/technologies-icons/ico_express.svg';
import { ReactComponent as IconRedis } from '../../assets/svg/technologies-icons/ico_redis.svg';
import { ReactComponent as IconNext } from '../../assets/svg/technologies-icons/ico_next.svg';
import { ReactComponent as IconWebrtc } from '../../assets/svg/technologies-icons/ico_webrtc.svg';
import { ReactComponent as IconEthereum } from '../../assets/svg/technologies-icons/ico_ethereum.svg';
import { ReactComponent as IconMongo } from '../../assets/svg/technologies-icons/ico_mongo.svg';
import { ReactComponent as IconSass } from '../../assets/svg/technologies-icons/ico_sass.svg';
import { ReactComponent as IconGraph } from '../../assets/svg/technologies-icons/ico_graph.svg';
import { ReactComponent as IconIos } from '../../assets/svg/technologies-icons/ico_ios.svg';
import { ReactComponent as IconAndroid } from '../../assets/svg/technologies-icons/ico_android.svg';
//import { ReactComponent as IconFirebase } from '../../assets/svg/technologies-icons/firebase-icon.svg';

import classes from './style.module.scss';

const Technologies = ({
  webpack,
  express,
  node,
  graph,
  mongo,
  redis,
  react,
  ios,
  android,
  redux,
  sass,
  next,
  webrtc,
  postgre,
  ethereum,
  hover,
  scale,
  firebase,
}) => {
  const labelClassStyle = hover
    ? [classes.Label, classes.HoverLabel].join(' ')
    : classes.Label;
  const technologieStyle = scale
    ? [classes.Technologie, classes.ScaleTechnologie].join(' ')
    : classes.Technologie;

  return (
    <>
      {webpack && (
        <div className={technologieStyle}>
          <div className={classes.Webpack}>
            <IconWebpack />
          </div>
          <div className={labelClassStyle}>WebpackJS</div>
        </div>
      )}
      {express && (
        <div className={technologieStyle}>
          <div className={classes.Express}>
            <IconExpress />
          </div>
          <div className={labelClassStyle}>ExpressJS</div>
        </div>
      )}
      {node && (
        <div className={technologieStyle}>
          <div className={classes.Node}>
            <IconNode />
          </div>
          <div className={labelClassStyle}>NodeJS</div>
        </div>
      )}
      {graph && (
        <div className={technologieStyle}>
          <div className={classes.Graph}>
            <IconGraph />
          </div>
          <div className={labelClassStyle}>GraphQL</div>
        </div>
      )}
      {mongo && (
        <div className={technologieStyle}>
          <div className={classes.Mongo}>
            <IconMongo />
          </div>
          <div className={labelClassStyle}>MongoDB</div>
        </div>
      )}
      {redis && (
        <div className={technologieStyle}>
          <div className={classes.Redis}>
            <IconRedis />
          </div>
          <div className={labelClassStyle}>Redis</div>
        </div>
      )}
      {react && (
        <div className={technologieStyle}>
          <div className={classes.React}>
            <IconReact />
          </div>
          <div className={labelClassStyle}>React</div>
        </div>
      )}
      {ios && (
        <div className={technologieStyle}>
          <div className={classes.Ios}>
            <IconIos />
          </div>
          <div className={labelClassStyle}>IOS</div>
        </div>
      )}
      {android && (
        <div className={technologieStyle}>
          <div className={classes.Android}>
            <IconAndroid />
          </div>
          <div className={labelClassStyle}>Android</div>
        </div>
      )}
      {redux && (
        <div className={technologieStyle}>
          <div className={classes.Redux}>
            <IconRedux />
          </div>
          <div className={labelClassStyle}>Redux</div>
        </div>
      )}
      {sass && (
        <div className={technologieStyle}>
          <div className={classes.Sass}>
            <IconSass />
          </div>
          <div className={labelClassStyle}>Sass</div>
        </div>
      )}
      {next && (
        <div className={technologieStyle}>
          <div className={classes.Next}>
            <IconNext />
          </div>
          <div className={labelClassStyle}>NextJS</div>
        </div>
      )}
      {webrtc && (
        <div className={technologieStyle}>
          <div className={classes.Webrtc}>
            <IconWebrtc />
          </div>
          <div className={labelClassStyle}>WebRTC</div>
        </div>
      )}
      {postgre && (
        <div className={technologieStyle}>
          <div className={classes.Postgre}>
            <IconPostgres />
          </div>
          <div className={labelClassStyle}>PostgreSQL</div>
        </div>
      )}
      {ethereum && (
        <div className={technologieStyle}>
          <div className={classes.Ethereum}>
            <IconEthereum />
          </div>
          <div className={labelClassStyle}>Ethereum</div>
        </div>
      )}
    </>
  );
};

export default Technologies;
