import React, { useState, useEffect } from 'react';
import JobDetalis from '../../components/content/jobs';
import CareersBanner from '../../components/banners/careers';
import OpenRoles from '../../components/content/openRoles';
import WorkWithUs from '../../components/content/workWithUs';
import CareersForm from '../../components/content/careerForm';
import URL from '../../jobsJSON.txt';
import { offers } from '../../utils';
import { Helmet } from 'react-helmet';

//const URL = 'https://reactweb-test.firebaseapp.com/exampleJSON.json';

export const Careers = ({ id, position }) => {
  // const [offers, setOffers] = useState([]);

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   const signal = abortController.signal;

  //   fetch(offers, { signal })
  //     .then(response => response.json())
  //     .then(data => setOffers(data.jobs))
  //     .catch(error => console.log(error));

  //   return () => {
  //     abortController.abort();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getCareersComponents = () => (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Careers | React Poland</title>
        <meta property='og:title' content='Careers | React Poland' />
        <meta
          name='description'
          content='As a fast growing organization, we need new passionate geeks on board. Find the vacancy for you and become a part of our great team.'
        />
        <meta
          property='og:description'
          content='As a fast growing organization, we need new passionate geeks on board. Find the vacancy for you and become a part of our great team.'
        />
        <meta property='og:image' content='../../assets/images/careers.png' />
        <meta property='og:url' content='www.reactpoland.com/careers/' />
      </Helmet>
      <CareersBanner />
      <OpenRoles data={offers} />
      <WorkWithUs />
      <CareersForm />
    </>
  );

  return (
    <div className='MainContent'>
      {id && position ? (
        <JobDetalis data={offers} id={id} position={position} />
      ) : (
        getCareersComponents()
      )}
    </div>
  );
};

export default Careers;
