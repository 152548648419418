import React from 'react';
import Banner from '../../components/banners/home';
import Services from '../../components/content/services';
import AppTypes from '../../components/content/appTypes';
import Contact from '../../components/content/contact';
import Testimonial from '../../components/content/testimonial';
import classes from './styles.module.scss';
import { Helmet } from 'react-helmet';

export const Home = () => {
  return (
    <div className={classes.MainContent}>
      <Helmet>
        <meta
          property='og:title'
          content={`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}`}
        />
        <meta charSet='utf-8' />
        <title>
          {`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}`}
        </title>

        <meta
          name='description'
          content={`React Poland: ReactJS and React Native developers for hire 2014-${new Date().getFullYear()}: The React Poland is a focused web and mobile applications development company located in Poland. The company was founded in 2014, and all our past and current projects use ReactJS or React-Native. No compromises here, React is our main focus.`}
        />
        <meta
          property='og:description'
          content='The React Poland is a focused web and mobile applications development company located in Poland. The company was founded in 2014, and all our past and current projects use ReactJS or React-Native. No compromises here, React is our main focus.'
        />
        <meta
          property='og:image'
          content='https://uploads-ssl.webflow.com/5cc2e264e18ab81050275c14/5d0365ce5349348eba96023c_image.png'
        />
        <meta property='og:url' content='https://www.reactpoland.com/' />
      </Helmet>
      <Banner />
      <Services />
      <AppTypes />
      <Testimonial />
      <Contact />
    </div>
  );
};

export default Home;
