import React from 'react';

const TextArea = ({
  className,
  cols,
  onChange,
  name,
  placeholder,
  rows,
  value,
  required,
}) => {
  return (
    <textarea
      className={className}
      cols={cols}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      rows={rows}
      value={value}
      required={required}
    />
  );
};

export default TextArea;
