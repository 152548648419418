import React from 'react';
import Experty from './projects/Experty';
import NurseFly from './projects/NurseFly';
import Iron from './projects/Iron';
// import Button from '../../buttons';
import classes from './style.module.scss';

const OurWork = () => {
  return (
    <section className={classes.OurWork}>
      <Experty />
      <NurseFly />
      <Iron />
      {/* <Button
        text='LOAD MORE'
        className={classes.ButtonLoad}
        onClick={() => console.log('clicked load more')}
      />
     */}
    </section>
  );
};

export default OurWork;
