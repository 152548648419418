import React from 'react';
import { navigate } from 'hookrouter';
import Logo from '../../../assets/images/LogoBlue.png';
import { ReactComponent as Location } from '../../../assets/svg/location.svg';
import { ReactComponent as WifiConnection } from '../../../assets/svg/wifi-connection.svg';
import { ReactComponent as Document } from '../../../assets/svg/document.svg';
import { ReactComponent as House } from '../../../assets/svg/home-run.svg';
import classes from './style.module.scss';

const OpenRoles = ({ data, hideText }) => {
  const displayOffer = (id, title) => {
    navigate(`/careers/${id}/${title}/`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className={[classes.OpenRoles, hideText].join(' ')}>
      <div className={classes.Ads}>
        {data.map(
          ({ id, title, date, description, location, option, type }) => {
            const setOptionClass =
              option === 'Inhouse' ? classes.House : classes.Wifi;
            return (
              <div
                key={id}
                className={classes.Rectangle}
                onClick={() => displayOffer(id, title)}
              >
                <div className={classes.Logo}>
                  <img src={Logo} alt='website React Poland logo' />
                </div>
                <div className={classes.AdsTitle}>{title}</div>
                {/* <div className={classes.AdsStart}>{setDatePeriod(date)}</div>*/}
                <div className={classes.Description}>{description}</div>
                <div className={classes.AdsFooter}>
                  <div className={classes.Location}>
                    <Location />
                    <p>{location}</p>
                  </div>
                  <div className={classes.Option}>
                    <span className={setOptionClass}>
                      {option === 'Inhouse' ? <House /> : <WifiConnection />}
                    </span>
                    <p>{option}</p>
                  </div>
                  <div className={classes.Type}>
                    <Document />
                    <p>{type}</p>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default OpenRoles;
