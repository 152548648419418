import React, { useState } from "react";
import Input from "../../inputs";
import TextArea from "../../inputs/TextArea";
import classes from "./style.module.scss";
import { ReactComponent as Mail } from "../../../assets/svg/email-blue.svg";
import { ReactComponent as User } from "../../../assets/svg/user.svg";
import { ReactComponent as Project } from "../../../assets/svg/project.svg";
import { ReactComponent as Work } from "../../../assets/svg/work.svg";
//import emailjs from 'emailjs-com';
import Button from "../../buttons";

const Modal = () => {
  const hide = () => {
    document.getElementById("Modal").style.display = "none";
  };

  return (
    <div className={classes.Modal} id="Modal">
      <h1>Your Message has been sent! Thank You!</h1>
      <br />
      <Button className={classes.Button} onClick={hide} text="Close" />
    </div>
  );
};

const CareersForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState({});
  const [message, setMessage] = useState("");
  const [filePath, setFilePath] = useState("");

  const getFileName = (path) => {
    if (path) {
      return path.substring(path.lastIndexOf("\\") + 1, path.length);
    }
    return "Upload your Resume";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      name,
      email,
      role,
      link,
      file,
      message,
    };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result;
      console.log(base64String);
      if (file.size <= 2097152) {
        window.Email.send({
          SecureToken: "e219d6b8-c6a6-44df-8da5-57f41c8c1e31",
          To: "paulina@reactpoland.com",
          From: email,
          Subject: "Application ReactPoland",
          Body: `<strong>Site:</strong><br />
            React Poland<br />
            <br />
            <strong>Submitted content:</strong><br />
            <br />
            Name / Company Name: ${name}&nbsp;<br />
            Email: ${email}<br />
            Role: ${role}<br />
            GitHub Link: ${link}<br />
            <strong>Field:</strong><br />${message}
            <br />`,
          Attachments: [
            {
              name: file.name,
              data: base64String,
            },
          ],
        }).then((message) => {
          document.getElementById("Modal").style.display = "flex";
          setName("");
          setEmail("");
          setRole("");
          setMessage("");
          setFile({});
          setFilePath("");
          setLink("");
        });
      } else {
        alert("File is too big");
      }
      if (file.size <= 0) {
        alert("File is too small");
      }
    };
  };

  return (
    <section className={classes.CareersForm}>
      <header>
        <h2>If you haven't found anything...</h2>
        <p>
          Surprise! Feel free to apply anyway. Just click the Apply button and
          follow the path there. We are always happy to talk with the React
          enthusiasts.
        </p>
      </header>
      <div className={classes.FormWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={classes.InputWrapper}>
            <Input
              className={classes.Input}
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              type="text"
              value={name}
            />
            <User className={classes.FormIcons} />
          </div>
          <div className={classes.InputWrapper}>
            <Input
              className={classes.Input}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="*Your e-mail here..."
              type="email"
              value={email}
              required
            />
            <Mail className={[classes.FormIcons, classes.Mail].join(" ")} />
          </div>
          <div className={classes.InputWrapper}>
            <Input
              className={classes.Input}
              name="role"
              onChange={(e) => setRole(e.target.value)}
              placeholder="Your Role Title"
              type="text"
              value={role}
            />
            <Work className={[classes.FormIcons, classes.Work].join(" ")} />
          </div>
          <div
            className={[classes.InputWrapper, classes.UploadWrapper].join(" ")}
          >
            <Input
              id="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setFilePath(e.target.value);
              }}
              style={{ display: "none" }}
              type="file"
              value={filePath}
              required
            />
            <p>*{getFileName(filePath)}</p>

            <label
              className={[classes.Button, classes.ButtonLabel].join(" ")}
              htmlFor="file"
            >
              UPLOAD
            </label>
          </div>
          <div className={[classes.InputWrapper, classes.Large].join(" ")}>
            <Input
              className={classes.Input}
              name="link"
              onChange={(e) => setLink(e.target.value)}
              placeholder="Link to your portfolio / github profile"
              type="text"
              value={link}
            />
            <Project
              className={[classes.FormIcons, classes.Project].join(" ")}
            />
          </div>
          <div className={[classes.InputWrapper, classes.Textarea].join(" ")}>
            <TextArea
              className={[classes.Input, classes.Area].join(" ")}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              placeholder="*Tell us more about yourself..."
              value={message}
              required
            />
            <p className={classes.SmallInfo}>*required</p>
          </div>
          <Button
            className={[classes.Button, classes.Submit].join(" ")}
            text="APPLY"
            className={classes.SubmitBtn}
            disabled={!email || !filePath || !message}
            type="submit"
          />
        </form>

        <Modal />
      </div>
    </section>
  );
};

export default CareersForm;
