import React from "react";
import PortofiloBanner from "../../components/banners/portfolio";
import OurWork from "../../components/content/ourWork";
import { Helmet } from "react-helmet";

export const Portfolio = () => {
  return (
    <div className="MainContent">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Work | React Poland</title>
        <meta property="title" content="Our Work | React Poland" />
        <meta
          name="description"
          content="We create Apps & Websites Systems. 30+ Satisfied customers. 40+ Projects delivered. 20+ Team Members"
        />
        <meta property="og:title" content="Our Work | React Poland" />
        <meta
          property="og:description"
          content="We create Apps & Websites Systems. 30+ Satisfied customers. 40+ Projects delivered. 20+ Team Members"
        />
        <meta
          property="og:image"
          content="https://uploads-ssl.webflow.com/5cc2e264e18ab81050275c14/5d0365ce5349348eba96023c_image.png"
        />
        <meta property="og:url" content="www.reactpoland.com/portfolio/" />
      </Helmet>
      <PortofiloBanner />
      <OurWork />
    </div>
  );
};

export default Portfolio;
