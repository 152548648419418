import React from 'react';
import Technologies from '../../../technologies';
import IronImage from '../../../../assets/images/Iron.png';
import IronApp from '../../../../assets/images/iron-app.png';
import classes from './style.module.scss';

const Iron = () => {
  return (
    <article className={classes.ProjectWrapper}>
      <div className='MainWrapper'>
        <div className={classes.Project}>
          <p className={classes.ProjectNumber}>Project 0.3</p>
          <header className={classes.Title}>
            <h2>IRON App</h2>
          </header>
          <p className={classes.Description}>
            Iron is an application created for personal trainers to manage their
            athletes in a new, digital way.
          </p>
          <p className={classes.DetailsTitle}>
            Training managing has never been simpler.
          </p>
          <p className={[classes.Description, classes.LargeSpace].join(' ')}>
            IRON allows for creating custom workouts for them, using predefined
            workouts or creating their own ones, scheduling trainings, tracking
            progress and give personalized advice via embedded chat system.
          </p>
        </div>
        <div className={classes.FlexWrapper}>
          <div className={classes.Technologies}>
            <p className={classes.TechnologiesLabel}>Used technologies</p>
            <div className={classes.TechnologiesList}>
              <Technologies webpack express node graph react redux postgre />
            </div>
          </div>
          <img src={IronApp} alt='Iron App' className={classes.Iron} />
        </div>
        <div
          className={[classes.SideInscription, classes.SideInscIron].join(' ')}
        >
          IRON App
        </div>
      </div>
      <img src={IronImage} alt='Iron' className={classes.FooterImage} />
    </article>
  );
};

export default Iron;
