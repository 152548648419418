import React from 'react';

const Input = ({
  className,
  id,
  name,
  onChange,
  placeholder,
  style,
  type,
  value,
  required,
  inputMode,
  pattern,
}) => {
  return (
    <input
      className={className}
      id={id}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      style={style}
      type={type}
      value={value}
      required={required}
      inputMode={inputMode}
      pattern={pattern}
    />
  );
};

export default Input;
