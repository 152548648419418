import React, { useState } from "react";
import Input from "../../inputs/index";
import Textarea from "../../inputs/TextArea";
import { ReactComponent as Mail } from "../../../assets/svg/email-blue.svg";
import { ReactComponent as Phone } from "../../../assets/svg/phone-blue.svg";
import { ReactComponent as User } from "../../../assets/svg/user.svg";
import classes from "./style.module.scss";
import Button from "../../buttons";
import { Link } from "react-router-dom";

const Modal = () => {
  const hide = () => {
    document.getElementById("Modal").style.display = "none";
  };

  return (
    <div className={classes.Modal} id="Modal">
      <h1>Your Message has been sent! Thank You!</h1>
      <br />
      <Button className={classes.Button} onClick={hide} text="Close" />
    </div>
  );
};

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    window.Email.send({
      SecureToken: "e219d6b8-c6a6-44df-8da5-57f41c8c1e31",
      To: "matt@reactpoland.com",
      From: email,
      Subject: "New form submission - React Poland",
      Body: `<strong>Site:</strong><br />
            React Poland<br />
            <br />
            <strong>Submitted content:</strong><br />
            <br />
            Name / Company Name: ${name}&nbsp;<br />
            Email: ${email}<br />
            <br />
            <strong>Field:</strong><br />${message}
            <br />`,
    }).then((message) => {
      document.getElementById("Modal").style.display = "flex";
      setEmail("");
      setName("");
      setMessage("");
    });

    e.target.reset();
  };

  return (
    <section className={classes.Contact}>
      <div className="MainWrapper">
        <div id="contact-form" className={`${classes.Description} TextStyles`}>
          <h3>
            Would you like to cooperate?
            <br />
            Go ahead and talk with us!
          </h3>
          <p>
            We would love to get to know you, and your ideas better. Simply use
            the contact form to share your basic information, for starters. We
            promise to get back to you as soon, as we can. You can also contact
            us directly at:{" "}
            <a href="mailto:hello@reactpoland.com">hello@reactpoland.com</a>
          </p>
          <form className={classes.Form} onSubmit={handleSubmit}>
            <div className={classes.Row}>
              <div className={classes.WithIco}>
                <Input
                  required
                  type="email"
                  placeholder={"*Your e-mail here..."}
                  className={classes.Input}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="from_email"
                />
                <Mail className={classes.MailIco} />
                <p className={classes.SmallInfo}>*required</p>
              </div>
              <div className={classes.WithIco}>
                <Input
                  type="name"
                  placeholder={"Name/Company Name"}
                  className={classes.Input}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="form_name"
                />
                <User className={classes.FormIcons} />
              </div>
            </div>
            <div className={classes.Row}>
              <Textarea
                cols="30"
                rows="4"
                placeholder={"*Your message here..."}
                className={classes.Textarea}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                name="html_message"
                required
              />
            </div>
            <div className={classes.Row}>
              <Button
                type="submit"
                className={classes.SubmitBtn}
                disabled={!email || !message}
                text="Contact us"
              />
            </div>
          </form>
          <Modal />
        </div>
      </div>
    </section>
  );
};

const vaildate = (values) => {
  if (!values.email) {
    return console.log("E-mail is required");
  }
  if (!values.message) {
    return console.log("Message is required");
  }
  return values;
};

export default Contact;
