import React from 'react';
import Technologies from '../../../technologies';
import Phones from '../../../../assets/images/Phones.png';
import ExpertyImage from '../../../../assets/images/experty.png';
import classes from './style.module.scss';

function Experty() {
  return (
    <article className={classes.ProjectWrapper}>
      <div className='MainWrapper'>
        <div className={classes.Project}>
          <p className={classes.ProjectNumber}>Project 0.1</p>
          <header className={classes.Title}>
            <h2>Experty APP</h2>
          </header>
          <p className={classes.Description}>
            Experty is a tool that connects experts, influencers, and
            celebrities to their community. Users can set their price and open
            up a new stream of revenue using secure peer-to-peer calls with an
            integrated real-time payment system.
          </p>
          <p className={classes.DetailsTitle}>Guarantee fair communication</p>
          <p className={classes.Description}>
            What do scribes, writers, and radio or tv speakers have in common?
            They have been rewarded for their knowledge by their respective
            media over centuries. What is happening now goes against this.
            People are enjoying the greatest access to knowledge of all time,
            but... the value is being kept by the very platforms allowing that
            access!
          </p>
          <p className={classes.DetailsTitle}>Blockchain data security</p>
          <p className={classes.Description}>
            We believe that blockchain will be an essential technology in
            developing secured applications. It has a huge potential to bring
            improvements into the digital world. Valuable aspect of blockchain
            technology is the complete decentralization thanks to which data are
            never stored in only one place. As soon as we discovered how
            suitable this technology is for the Experty app, we didn’t hesitate
            to take advantage of it.
          </p>
        </div>
        <div className={classes.FlexWrapper}>
          <div className={classes.Technologies}>
            <p className={classes.TechnologiesLabel}>Used technologies</p>
            <div className={classes.TechnologiesList}>
              <Technologies
                webpack
                express
                node
                redis
                react
                redux
                next
                webrtc
                postgre
                ethereum
              />
            </div>
          </div>
          <img src={Phones} alt='Phones' className={classes.Phones} />
        </div>
        <div className={classes.SideInscription}>Experty App</div>
      </div>
      <img src={ExpertyImage} alt='' className={classes.FooterImage} />
    </article>
  );
}

export default Experty;
