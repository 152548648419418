import React from 'react';
import { ReactComponent as ReactIco } from '../../../assets/svg/react-icon-white.svg';
import classes from './style.module.scss';

const CareersBanner = () => {
  return (
    <section className={classes.Banner}>
      <div className={classes.Description}>
        <div className='MainWrapper'>
          <div className={classes.Content}>
            <h1>Careers</h1>
            <p>
              As a fast growing organization, we constantly need new passionate geeks on
              board. Below you can find the vacancy for you and become a part of our fabulous
              team.
            </p>
            <div className={classes.ReactIcon}>
              <ReactIco />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classes.BcgWave}>
				<div className={classes.HeaderWave}>
					<svg viewBox='0 0 500 150' preserveAspectRatio='none'>
						<path
							d='M0.00,49.98 C350.73,-12.33 352.42,249.17 500.00,49.98 L500.00,0.00 L0.00,0.00 Z'
							style={{ stroke: 'none', fill: '#0f82b7' }}
						/>
					</svg>
				</div>
			</div> */}
    </section>
  );
};

export default CareersBanner;
