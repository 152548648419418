import React from 'react';
import classes from './style.module.scss';
import { A } from 'hookrouter';

const Error = () => (
  <section className={classes.ErrorInformation}>
    <div className={classes.Information}>
      The URL is not valid
      <br />
      <br />
      404
    </div>
    <div className={classes.Redirect}>
      <A href='/'>Go to home</A>
    </div>
  </section>
);

export default Error;
