import React from 'react';
import { A } from 'hookrouter';
import IconLeft from '../../../../assets/icons/arrow-left.png';
import classes from './style.module.scss';

const Redirect = () => {
  return (
    <aside className={classes.BackSection}>
      <div>
        <A href='/careers/'>
          <img src={IconLeft} alt='Arrow left' />
          Back to Careers
        </A>
      </div>
      <div className={classes.RotateText}>Open Roles</div>
    </aside>
  );
};

export default Redirect;
