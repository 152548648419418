import React, { useState, useEffect } from 'react';
import { exampleCode } from '../../utils';
import classes from './style.module.scss';

const speed = 70;

const CodeAnimation = () => {
  const [textPosition, setTextPosition] = useState(0);
  const [delay, setDelay] = useState(4000);
  const [showCodeAnimation, setShowCodeAnimation] = useState(false);

  const getLineOfCodeNumbers = () => {
    let index = 0;
    return Array(25)
      .fill()
      .map(() => <span key={index}>{`${++index}.`}</span>);
  };

  useEffect(() => {
    const interval = setInterval(type, delay);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textPosition]);

  const type = () => {
    if (exampleCode.length > textPosition) {
      if (delay !== speed) {
        setShowCodeAnimation(true);
        setDelay(speed);
      }
      setTextPosition(textPosition + 1);
      exampleCode.length - 3 < textPosition && setDelay(4000);
    } else {
      setDelay(speed);
      setTextPosition(0);
    }
  };

  if (!showCodeAnimation) return null;

  return (
    <div className={classes.TextAnimation}>
      <div className={classes.Numbers}>{getLineOfCodeNumbers()}</div>
      <div className={classes.WrapperText}>
        <span>{exampleCode.substr(0, textPosition)}</span>
        <div className={classes.Cursor}>|</div>
        <div className={classes.HideExample} />
      </div>
    </div>
  );
};

export default CodeAnimation;
