import React from 'react';
import { ReactComponent as World } from '../../../assets/svg/benefits/world.svg';
import { ReactComponent as Sport } from '../../../assets/svg/benefits/athlete.svg';
import Pizza from '../../../assets/svg/benefits/pizza.png';
import { ReactComponent as Internet } from '../../../assets/svg/benefits/internet.svg';
import { ReactComponent as Trust } from '../../../assets/svg/benefits/trust.svg';
import Time from '../../../assets/icons/time.png';
import classes from './style.module.scss';

const WorkWithUs = () => (
  <section className={classes.WorkWithUs}>
    <header>
      <h2>Company perks which we all enjoy...</h2>
    </header>
    <div className={classes.Assets}>
      <div className={classes.Item}>
        <div className={classes.Icon}>
          <World />
        </div>
        <div className={classes.Description}>
          <p>Global clients</p>
          <p>Cooperate with the worldwide clients</p>
        </div>
      </div>
      <div className={classes.Item}>
        <div className={classes.Icon}>
          <img src={Time} alt='time' />
        </div>
        <div className={classes.Description}>
          <p>Flexible working time</p>
          <p>Adjust your working schedule to your needs</p>
        </div>
      </div>
      <div className={classes.Item}>
        <div className={classes.Icon}>
          <Sport />
        </div>
        <div className={classes.Description}>
          <p>Multisport card</p>
          <p>Enjoy non-limited gym after hours</p>
        </div>
      </div>

      <div className={classes.Item}>
        <div className={[classes.Icon, classes.Pizza].join(' ')}>
          <img className={classes.Pizza} src={Pizza} alt='pizza' />
        </div>
        <div className={classes.Description}>
          <p>Lunch and learn</p>
          <p>Join our friday tradition of sharing knowledge and pizza</p>
        </div>
      </div>
      <div className={classes.Item}>
        <div className={classes.Icon}>
          <Internet />
        </div>
        <div className={classes.Description}>
          <p>Remote work</p>
          <p>Just pick a day and work from home</p>
        </div>
      </div>
      <div className={classes.Item}>
        <div className={classes.Icon}>
          <Trust />
        </div>
        <div className={classes.Description}>
          <p>Team events</p>
          <p>Join our frequent trips and parties</p>
        </div>
      </div>
    </div>
  </section>
);

export default WorkWithUs;
