import React from "react";
import { A } from "hookrouter";
import { ReactComponent as Logo } from "../../../assets/svg/logo-white-svg.svg";
import { ReactComponent as Linkedin } from "../../../assets/svg/in-logo-white-circle.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/fb-logo-white-circle.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram-logo-white-circle.svg";
import classes from "./style.module.scss";

const Footer = () => {
  const toTheTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const scrollToContactForm = () => {
    setTimeout(() => {
      const contactForm = document.getElementById("contact-form");
      contactForm && contactForm.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };
  return (
    <footer className={classes.Footer}>
      <div className={classes.FooterWave}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M0.00,49.98 C149.99,150.00 365.40,150.48 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: "#0f82b7" }}
          />
        </svg>
      </div>
      <div className="MainWrapper">
        <div className={classes.FooterInfo}>
          <div className={classes.Info}>
            <div>
              <h5>Main Office</h5>
              <p>ul. Jana Siemińskiego 11/9</p>
              <p>44-100 Gliwice</p>
            </div>

            <br />
            <div>
              <p>NIP: 6312689902</p>
              <p>KRS: 0000828135</p>
              <p>REGON: 385523685</p>
            </div>
          </div>
          <div className={classes.MiddleInfo}>
            <div className={classes.Logo}>
              <Logo />
            </div>
            <p>ReactPoland.com</p>
            <a href={"mailto:hello@reactpoland.com"}>
              <p>hello@reactpoland.com</p>
            </a>
            <div className={classes.SocialMedia}>
              <a
                href="https://pl.linkedin.com/company/react-poland"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
              <a
                href="https://www.facebook.com/reactpolandsoftware/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/reactpolandsoftware/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </div>
          </div>
          <div className={classes.Info}>
            <h5>Navigation</h5>
            <nav>
              <ul>
                <li>
                  <A href="/portfolio/" onClick={() => toTheTop()}>
                    Our Work
                  </A>
                </li>
                <li>
                  <A href="/careers/" onClick={() => toTheTop()}>
                    Careers
                  </A>
                </li>
                <li>
                  <A href="/" onClick={scrollToContactForm}>
                    Contact
                  </A>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={classes.Rights}>
          <p>{`All rights reserved ${new Date().getFullYear()} © React Poland`}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
