import React from "react";
import Phone from "./../../../assets/images/Phones.png";
import Logo from "./../../../assets/images/LogoBlue.png";
import classes from "./style.module.scss";

export const Services = () => (
  <section className={classes.Services}>
    <div className="MainWrapper">
      <div className="SmallWrap">
        <div className={classes.Card}>
          <div className={`${classes.Description} TextStyles`}>
            <div className={classes.Logo}>
              <img src={Logo} alt="website logo" />
            </div>
            <h3>Apps from A to Z</h3>
            <p>
              Got an idea? We are here for you to make it digital. Give us a
              sneak peek of what you would like to achieve and simply let us
              create the perfect app for you, step by step. Read more about the
              path...
              <br />
              <br />
              <span>1. Analysis</span>
              <br />
              Research, discussion, and properly chosen technologies based on
              our customer's expectations are the elements which we combine to
              draw out the best and most desirable product.
              <br />
              <br />
              <span>2. Definition</span>
              <br />
              Defining is a process during which we decide the ideal outcome of
              our work. We always want to make sure it is clear and
              understandable for both, our customer and teams who are going to
              be involved in the project.
              <br />
              <br />
              <span>3. Design</span>
              <br />
              We match the branch, type, or dream appearance of the product with
              its practical aspects and create the most suitable and outstanding
              designs and user's flow.
              <br />
              <br />
              <span>4. Development</span>
              <br />
              The development process is one of the most important part of
              production. Our team crafts a product using a clear understanding
              of the customer's design and technology needs.
            </p>
          </div>
          <div className={classes.Image}>
            <img src={Phone} alt="phone" />
          </div>
        </div>
        <div className={classes.Row}>
          <div className={classes.SmallCard}>
            <div className={"TextStyles"}>
              <div className={classes.Logo}>
                <img src={Logo} alt="website logo" />
              </div>
              <h3>Consulting</h3>
              <p>
                Are you craving a good advice? If your app already exists, but
                it just does not seem to be perfect - contact us. We are able to
                help you review and rewrite the code, if necessary. Major
                mistakes and minor fixes can be resolved accordingly to your
                desired needs.
              </p>
            </div>
          </div>
          <div className={classes.SmallCard}>
            <div className={"TextStyles"}>
              <div className={classes.Logo}>
                <img src={Logo} alt="website logo" />
              </div>
              <h3>Support for your project</h3>
              <p>
                Do you consider an extra developers who will speed up your work?
                Our team is ready to support the projects which are
                understaffed, need specialists or simply additional project
                members. We have been helping on a very different stages of the
                app development in the past. That experience taught us, that
                thanks to the good introduction and smooth communication, we are
                able to join any team during app creation process and help you
                succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Services;
