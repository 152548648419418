import React from "react";
import CodeAnimation from "../../codeAnimation";
import { ReactComponent as ReactIco } from "../../../assets/svg/react-icon-white.svg";
import HomeBannerImage from "../../../assets/images/home-banner.png";
import MobileLaptopBanner from "../../../assets/images/bannerLaptopPhone.png";

import classes from "./style.module.scss";

const Banner = () => (
  <section className={classes.Banner}>
    <div className="MainWrapper">
      <div className={classes.Description}>
        <h1>Leading in React Software Development</h1>
        <p>
          Passionate and experienced in crafting web and mobile applications
          since 2014
        </p>
      </div>
      <div className={classes.Images}>
        <ReactIco />
        <div className={classes.BannerImageWrapper}>
          <img
            src={HomeBannerImage}
            alt="banner"
            className={classes.ImgBanner}
          />
          <CodeAnimation />
        </div>
        <div className={classes.MobileBanner}>
          <img
            src={MobileLaptopBanner}
            alt="laptop"
            className={classes.MobileLaptopBanner}
          />
        </div>
      </div>
    </div>
    <div className={classes.BcgWave}>
      <div className={classes.HeaderWave}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none">
          <path
            d="M0.00,49.98 C350.73,-12.33 352.42,249.17 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#0f82b7" }}
          />
        </svg>
      </div>
    </div>
  </section>
);

export default Banner;
