import React from "react";
import { Helmet } from "react-helmet";
import Error from "../../components/banners/404/";

export const ErrorPage = () => {
  return (
    <div className="MainContent">
      <Helmet>
        <meta property="prerender-status-code" content="404" />
        <meta property="title" content="404 | React Poland" />
        <meta property="description" content="The URL is not valid" />
        <meta property="url" content="www.reactpoland.com/404/" />
      </Helmet>
      <Error />
    </div>
  );
};

export default ErrorPage;
