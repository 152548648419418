import React from 'react';
import Technologies from '../../../technologies';
import Desktop from '../../../../assets/images/desktop-2.png';
import NurseFlyImage from '../../../../assets/images/NurseFly.png';
import Phone from '../../../../assets/images/mobile-2.png';
import classes from './style.module.scss';
import LaptopPhone from '../../../../assets/images/bannerLaptopPhone.png';

export const NurseFly = () => {
  return (
    <article className={classes.ProjectWrapper}>
      <div className='MainWrapper'>
        <div className={classes.Project}>
          <p className={classes.ProjectNumber}>Project 0.2</p>
          <header className={classes.Title}>
            <h2>Nurse Fly App</h2>
          </header>
          <p className={classes.Description}>
            Get matched with travel nursing jobs you love.
          </p>
          <p className={classes.DetailsTitle}>
            Find jobs from multiple agencies and get paid what you deserve.
          </p>
          <p className={classes.Description}>
            On NurseFly, you are able to see transparent pay for travel nursing
            jobs across the country, research cost of living and quality of life
            information for over 3000 cities, and can immediately get connected
            to a relevant recruiter who can help place you in the best travel
            nursing job!
          </p>
        </div>
        <div className={classes.FlexWrapper}>
          <div className={classes.Technologies}>
            <p className={classes.TechnologiesLabel}>Used technologies</p>
            <div className={classes.TechnologiesList}>
              <Technologies webpack express node mongo react redux postgre />
            </div>
          </div>
          <img src={Desktop} alt='Desktop' className={classes.Desktop} />
          <img src={Phone} alt='Desktop' className={classes.Phone} />
          <img
            src={LaptopPhone}
            alt='Desktop'
            className={classes.LaptopPhone}
          />
        </div>
        <div
          className={[classes.SideInscription, classes.SideInscNurseFly].join(
            ' '
          )}
        >
          Nurse Fly
        </div>
      </div>
      <img src={NurseFlyImage} alt='' className={classes.FooterImage} />
    </article>
  );
};

export default NurseFly;
