import React from 'react';
import { navigate } from 'hookrouter';
import { ReactComponent as IconCalendar } from '../../../assets/svg/cal-clock.svg';
import DefaultArticleImage from '../../../assets/images/article-image.png';
import { articles } from '../../../utils';
import classes from './style.module.scss';

const Article = React.memo(({ id }) => {
  const currentArticle = articles.find(article => article.id === Number(id));

  if (!currentArticle) return <div>Article not exists</div>;

  const { date, title, article, tags, image } = currentArticle;
  const getPrevoiusArticleId = () => {
    const indexOfCurrentArticle = findIndex();
    if (indexOfCurrentArticle === 0) return articles.length;
    return indexOfCurrentArticle;
  };
  const getNextArticleId = () => {
    const indexOfCurrentArticle = findIndex();
    if (indexOfCurrentArticle === articles.length - 1) return 1;
    return indexOfCurrentArticle + 2;
  };
  const getPrevoiusArticleTitle = () => {
    const indexOfCurrentArticle = findIndex();
    if (indexOfCurrentArticle === 0) return articles[articles.length - 1].title;
    return articles[indexOfCurrentArticle - 1].title;
  };
  const getNextArticleTitle = () => {
    const indexOfCurrentArticle = findIndex();
    if (indexOfCurrentArticle === articles.length - 1) return articles[0].title;
    return articles[indexOfCurrentArticle + 1].title;
  };

  const findIndex = () =>
    articles.findIndex(article => article.id === Number(id));

  const handleRedirect = direct => {
    const id = direct === 'next' ? getNextArticleId() : getPrevoiusArticleId();
    navigate(`${id}`);
    window.scrollTo({ top: 700, behavior: 'smooth' });
  };

  return (
    <main className={classes.ArticleWrapper}>
      <div className={classes.ArticleContent}>
        <aside className={classes.Info}>
          <img src={image} alt='author' />
          <p className={classes.Author}>Paulina Woźniak</p>
          <p className={classes.Role}>Administartor</p>
          <div className={classes.Date}>
            <IconCalendar />
            <span>{date}</span>
          </div>
          <div className={classes.Tags}>
            {tags.map(tag => (
              <span key={tag}>{`#${tag} `}</span>
            ))}
          </div>
        </aside>
        <div className={classes.Article}>
          <header>
            <h1>{title}</h1>
            <p onClick={() => navigate('/blog')}>Back to Blog</p>
          </header>
          <div className={classes.Content}>
            <img src={DefaultArticleImage} alt='' />
            <div className={classes.Text}>{article}</div>
          </div>
          <div className={classes.Share}>
            <p>SHARE:</p>
            <p onClick={() => alert('facebook share')}>Facebook</p>
            <p onClick={() => alert('twitter share')}>Twitter</p>
            <p onClick={() => alert('instagram share')}>Instagram</p>
            <p onClick={() => alert('copy link')}>CopyLink</p>
          </div>
        </div>
      </div>
      <div className={classes.ArticleNav}>
        <p
          onClick={handleRedirect}
        >{`Previous ${getPrevoiusArticleTitle()}`}</p>
        <p
          onClick={() => handleRedirect('next')}
        >{`Next ${getNextArticleTitle()}`}</p>
      </div>
    </main>
  );
});

export default Article;
