import React from "react";
import { usePath } from "hookrouter";
import { testimonials } from "../../../utils";
import windowWidth from "../../../hooks/useWindowWidth";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import classes from "./style.module.scss";
import quote from "../../../assets/icons/quoteBlue.svg";

const Testimonial = () => {
  const currentPath = usePath();
  const whiteVersion = currentPath === "/about" ? classes.WhiteVersion : "";
  const { width } = windowWidth();

  return (
    <section className={`${classes.Testimonial} ${whiteVersion}`}>
      <Carousel
        centered
        //  dots={currentPath !== '/about'}
        draggable={true}
        infinite
        autoPlay={10000}
        animationSpeed={500}
        stopAutoPlayOnHover={false}
        itemWidth={width >= 1400 ? 670 : 500}
        keepDirectionWhenDragging={true}
        minDraggableOffset={1}
      >
        {testimonials.map(
          ({ id, src, alt, opinion, author, occupation, workPlace }) => (
            <div key={id} className={classes.ReviewCard}>
              {/*}   */}
              <div className={classes.Image}>
                <img src={src} alt={alt} />
              </div>

              <p className={classes.Opinion}>
                {opinion}
                <br />
              </p>
              <img className={classes.Quote} src={quote} alt="quote" />
              <p className={classes.Author}>{author}</p>
              <p className={classes.Occupation}>{occupation}</p>
              <p className={classes.Workplace}>{workPlace}</p>
            </div>
          )
        )}
      </Carousel>
    </section>
  );
};

export default Testimonial;
