import React, { useState } from 'react';
import { navigate } from 'hookrouter';
import { ReactComponent as IconCalendar } from '../../../assets/svg/cal-clock.svg';
import DefaultImage from '../../../assets/images/article-default.png';
import Input from '../../../components/inputs';
import Button from '../../../components/buttons';
import { articles } from '../../../utils';
import classes from './style.module.scss';

const exampleTags = [
  'design',
  'programing',
  'officelife',
  'workflow',
  'ui/ux',
  'reacjJS',
];

const exampleCategories = [
  'Office live (2)',
  'ReactJS (12)',
  'Web development (3)',
  'UX tips (3)',
  'Category (4)',
];

const limit = 1;

const Articles = () => {
  const [search, setSearch] = useState('');
  const [changeLimit, setChangeLimit] = useState(limit);

  const handleChange = e => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleChangeLimitUp = () => {
    const newLimit = changeLimit + limit;
    const setLimit = newLimit > articles.length ? articles.length : newLimit;
    setChangeLimit(setLimit);
  };

  const handleChangeLimitDown = () => {
    const newLimit = changeLimit - limit;
    const setLimit = newLimit < limit ? limit : newLimit;
    setChangeLimit(setLimit);
  };

  return (
    <>
      <div className={classes.ArticlesWrapper}>
        <section className={classes.Articles}>
          {articles
            .slice(0, changeLimit)
            .map(({ id, title, date, tags, image, article }, index) => (
              <article
                key={index}
                onClick={() => navigate(`/blog/article/${id}`)}
              >
                <div className={classes.Image}>
                  <img
                    src={DefaultImage}
                    alt='icon'
                    width='288px'
                    height='288px'
                  />
                </div>
                <div className={classes.ArticleContent}>
                  <header>
                    <h2>{title}</h2>
                  </header>
                  <div className={classes.Content}>
                    {`${article.substring(0, 285)} ...`}
                  </div>
                  <footer>
                    <div className={classes.Date}>
                      <IconCalendar />
                      <span>{date}</span>
                    </div>
                    <div className={classes.Tags}>
                      {tags.map(tag => (
                        <span key={tag}>{`#${tag} `}</span>
                      ))}
                    </div>
                  </footer>
                </div>
              </article>
            ))}
        </section>
        <aside>
          <div className={classes.SearchField}>
            <Input
              className={classes.SearchInput}
              placeholder='Search with tag'
              onChange={handleChange}
              value={search}
            />
          </div>
          <div className={classes.AsideSection}>
            <p>Popular Tags</p>
            <ul className={classes.PopularTags}>
              {exampleTags.map(tag => (
                <li key={tag}>
                  <span>{tag}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.AsideSection}>
            <p>Categories</p>
            <ul className={classes.PopularTags}>
              {exampleCategories.map(cat => (
                <li key={cat} onClick={() => console.log(cat)}>
                  {cat}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.AsideSection}>
            <div className={classes.LatestPost}>
              <p>Latest post</p>
              <ul>
                {articles.slice(0, 2).map(({ id, title }, index) => (
                  <li key={index} onClick={() => console.log(id)}>
                    {title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </aside>
      </div>
      <div className={classes.ButtonGroup}>
        <Button
          text={'Show more'}
          className={classes.Button}
          onClick={handleChangeLimitUp}
          hide={changeLimit >= articles.length}
        />
        <Button
          text={'Show less'}
          className={classes.Button}
          onClick={handleChangeLimitDown}
          hide={changeLimit <= limit}
        />
      </div>
    </>
  );
};

export default Articles;
