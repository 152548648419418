import React from "react";
import Technologies from "../../technologies";
import MapPhones from "../../../assets/images/MapPhones.png";
import Laptop from "../../../assets/images/Laptop.png";
import classes from "./style.module.scss";

const AppTypes = () => (
  <section className={classes.AppTypes}>
    <div className="MainWrapper">
      <div className={classes.Card}>
        <div className={classes.Image}>
          <img src={Laptop} alt="Laptop" className={classes.LaptopImg} />
        </div>
        <div className={classes.Description + ` TextStyles`}>
          <h3>WEB: React</h3>
          <p>
            Web applications are becoming even more common these days. The major
            plus is that those apps do not require downloading, neither your
            disc space. A well tailored Web app is able to take your business to
            the next level, so it must be created with the highest standard of
            the content and quality. Our team is experienced and passionate
            about React JS. Moreover, we create hi-tech apps assuring you both,
            front-end as well as back-end development. During every part of the
            process, we are working accordingly to our customers’ feedback and
            expectations.
          </p>
          <div>
            <p className={classes.SmallTitle}>Technologies</p>
            <div className={classes.Technologies}>
              <Technologies react postgre mongo node webrtc redux hover />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.Card}>
        <div className={classes.Description + ` TextStyles`}>
          <h3>MOBILE: React Native</h3>
          <p>
            Mobile applications which are built and designed with care are
            rewarding even for the most demanding customers' expectations.
            Thanks to the React Native, our professional team can assure you a
            professional and high quality outcome based on your vision and ideal
            result of your mobile app.
          </p>
          <div>
            <p className={classes.SmallTitle}>Technologies</p>
            <div className={classes.Technologies}>
              <Technologies react ios android mongo postgre webrtc hover />
            </div>
          </div>
        </div>
        <div className={classes.Image}>
          <img src={MapPhones} alt="Phones" className={classes.PhonesImg} />
        </div>
      </div>
    </div>
  </section>
);

export default AppTypes;
